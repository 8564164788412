import classNames from 'classnames';
import React from 'react';

import styles from './field.module.css';

export interface FieldWrapperProps {
  id?: string;
  label?: string;
  error?: string;
  note?: string | React.ReactNode;
  disabled?: boolean;
  hideError?: boolean;
  children?: React.ReactNode;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  children,
  disabled,
  error,
  hideError = false,
  id,
  label,
  note,
}) => (
  <div className={classNames(styles.UiField)}>
    {label ? <FieldLabel id={id} label={label} /> : null}

    <div
      className={classNames(
        styles.UiField__field,
        error && styles.UiField__field__error,
        disabled && styles.UiField__field__disabled,
      )}
    >
      {children}
    </div>

    {note ? <p className={classNames(styles.UiField__note)}>{note}</p> : null}

    {!hideError && error ? (
      <p className={classNames(styles.UiField__error)}>{error}</p>
    ) : null}
  </div>
);

export const FieldLabel = ({
  id,
  label,
}: Pick<FieldWrapperProps, 'label' | 'id'>) => (
  <label className={classNames(styles.UiField__label)} htmlFor={id}>
    {label}
  </label>
);
