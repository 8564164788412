import { ProductPurchaseDetails } from '../types/Purchasing';

export const framedByDefault = (
  framingOffered?: ProductPurchaseDetails['framingOffered'],
) => {
  return framingOffered === 'Frame included';
};

export const normaliseVariantName = (name: string) =>
  name.toLowerCase().trim().replace(/\s+/g, '-');
