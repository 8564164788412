import classNames from 'classnames';
import React, { forwardRef, Ref } from 'react';

import { Icon, IconName, Spinner } from '../Icon';
import styles from './button.module.css';

export type ButtonVariantType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'transparent'
  | 'positive'
  | 'danger'
  | 'info';
export type ButtonSizeType = 'xs' | 'sm' | 'md' | 'lg';
export type ButtonIconPositionType = 'left' | 'right';

interface ButtonBase {
  label: string;
  loading?: boolean;
  variant?: ButtonVariantType;
  size?: ButtonSizeType;
  fullWidth?: boolean;
  onClick?: () => void;
}

type ButtonLabelIcon = ButtonBase & {
  icon?: IconName;
  iconPosition?: ButtonIconPositionType;
  hideLabel?: boolean;
};

type ButtonIcon = ButtonBase & {
  icon: IconName;
  iconPosition?: ButtonIconPositionType;
  hideLabel?: true;
};

type ButtonPattern = ButtonLabelIcon | ButtonIcon;

type HrefInterface = React.HTMLAttributes<HTMLAnchorElement> &
  ButtonPattern & {
    as?: 'a';
    href: string;
    target?: '_blank';
    rel?: 'noreferrer';
  };

type ButtonInterface = React.HTMLAttributes<HTMLButtonElement> &
  ButtonPattern & {
    as?: 'button';
    type?: 'button' | 'submit';
    disabled?: boolean;
  };

export type ButtonProps = HrefInterface | ButtonInterface;

const isHref = (props: ButtonProps): props is HrefInterface => {
  return props.as === 'a';
};

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((props, ref) => {
  const innerChildren = (
    <>
      {!props.hideLabel ? (
        <span className={classNames({ 'opacity-0': props.loading }, 'px-1')}>
          {props.label}
        </span>
      ) : null}
      {props.icon ? (
        <Icon
          className={classNames({ 'opacity-0': props.loading })}
          name={props.icon}
        />
      ) : null}
      {props.loading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner />
        </div>
      ) : null}
    </>
  );

  const sizeMapper = {
    'xs': 'p-1',
    'sm': 'p-2',
    'md': 'p-3',
    'lg': 'p-4',
  };

  if (isHref(props)) {
    const {
      fullWidth = false,
      hideLabel = false,
      href,
      icon,
      iconPosition = 'right',
      onClick,
      size = 'lg',
      variant = 'primary',
    } = props;

    return (
      <a
        ref={ref as Ref<HTMLAnchorElement>}
        {...(props.hideLabel && { 'aria-label': props.label })}
        {...(props.target && { 'target': props.target })}
        {...(props.rel && { 'rel': props.rel })}
        className={classNames(
          variant === 'primary' && styles.UiButton__variant__primary,
          variant === 'secondary' && styles.UiButton__variant__secondary,
          variant === 'tertiary' && styles.UiButton__variant__tertiary,
          variant === 'transparent' && styles.UiButton__variant__transparent,
          variant === 'positive' && styles.UiButton__variant__positive,
          variant === 'danger' && styles.UiButton__variant__danger,
          variant === 'info' && styles.UiButton__variant__info,
          sizeMapper[size],
          icon && hideLabel ? 'rounded-full' : 'rounded-sm',
          fullWidth ? 'w-full' : 'shrink-0',
          {
            'flex-row-reverse': iconPosition === 'left',
          },
        )}
        href={href}
        onClick={onClick}
      >
        {innerChildren}
      </a>
    );
  }

  const {
    disabled,
    fullWidth = false,
    hideLabel = false,
    icon,
    iconPosition = 'right',
    loading = false,
    onClick,
    size = 'lg',
    type,
    variant = 'primary',
  } = props;

  return (
    <button
      ref={ref as Ref<HTMLButtonElement>}
      {...(props.hideLabel && { 'aria-label': props.label })}
      className={classNames(
        variant === 'primary' && styles.UiButton__variant__primary,
        variant === 'secondary' && styles.UiButton__variant__secondary,
        variant === 'tertiary' && styles.UiButton__variant__tertiary,
        variant === 'transparent' && styles.UiButton__variant__transparent,
        variant === 'positive' && styles.UiButton__variant__positive,
        variant === 'danger' && styles.UiButton__variant__danger,
        variant === 'info' && styles.UiButton__variant__info,
        sizeMapper[size],
        icon && hideLabel ? 'rounded-full' : 'rounded-sm',
        fullWidth ? 'w-full' : 'shrink-0',
        {
          'cursor-not-allowed': disabled || loading,
          'opacity-50':
            disabled && variant !== 'positive' && variant !== 'danger',
          'flex-row-reverse': iconPosition === 'left',
        },
      )}
      disabled={disabled || loading}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {innerChildren}
    </button>
  );
});
