/**
 ****** @Deprecated ******
 ****** Please create new queries/mutations using .graphql files******
 */

import { gql } from '@apollo/client';

export const SELECTED_PRODUCTS_BY_ID_QUERY = gql`
  query SelectedProducts($ids: [ID!]!, $country: CountryCode)
  @inContext(country: $country) {
    nodes(ids: $ids) {
      ... on Product {
        id
        title
        productType
        artistName: metafield(namespace: "my_fields", key: "artist_name") {
          value
          type
        }
        launchDate: metafield(namespace: "my_fields", key: "launch_date") {
          value
          type
        }
        framingOffered: metafield(
          namespace: "my_fields"
          key: "framing_offered"
        ) {
          value
          type
        }
        unframedDispatchDate: metafield(
          namespace: "my_fields"
          key: "unframed_dispatch_date"
        ) {
          value
          type
        }
        framedDispatchDate: metafield(
          namespace: "my_fields"
          key: "framed_dispatch_date"
        ) {
          value
          type
        }
        closingDate: metafield(
          namespace: "my_fields"
          key: "timed_release_closing_date"
        ) {
          value
          type
        }
        editionSize: metafield(namespace: "my_fields", key: "edition_size") {
          value
          type
        }
        releaseType: metafield(namespace: "my_fields", key: "release_type") {
          value
        }
        cryptoPrice: metafield(
          namespace: "my_fields"
          key: "crypto_display_price"
        ) {
          value
        }
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        tags
        handle
        onlineStoreUrl
        featuredImage {
          altText
          url
        }
        variants(first: 10) {
          edges {
            node {
              id
              title
              price {
                amount
              }
            }
          }
        }
        collectionSet: metafield(namespace: "custom", key: "set_collection") {
          value
          type
        }
      }
    }
  }
`;

export const ALL_PRODUCTS_IDS_QUERY = gql`
  query AllProductsIDs($filters: String!, $limit: Int!, $after: String) {
    products(query: $filters, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const ALL_PRODUCTS_QUERY = gql`
  query AllProducts(
    $country: CountryCode
    $filters: String!
    $limit: Int!
    $after: String
  ) @inContext(country: $country) {
    products(query: $filters, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          createdAt
          id
          title
          productType
          handle
          manifoldListingId: metafield(
            namespace: "custom"
            key: "manifold_listing_id"
          ) {
            value
          }
          auctionFloorPrice: metafield(
            namespace: "custom"
            key: "auction_floor_price"
          ) {
            value
          }
          editionSize: metafield(namespace: "my_fields", key: "edition_size") {
            value
          }
          artistName: metafield(namespace: "my_fields", key: "artist_name") {
            value
          }
          launchDate: metafield(namespace: "my_fields", key: "launch_date") {
            value
          }
          cryptoPrice: metafield(
            namespace: "my_fields"
            key: "crypto_display_price"
          ) {
            value
          }
          closingDate: metafield(
            namespace: "my_fields"
            key: "timed_release_closing_date"
          ) {
            value
          }
          releaseType: metafield(namespace: "my_fields", key: "release_type") {
            value
          }
          framingOffered: metafield(
            namespace: "my_fields"
            key: "framing_offered"
          ) {
            value
            type
          }
          unframedDispatchDate: metafield(
            namespace: "my_fields"
            key: "unframed_dispatch_date"
          ) {
            value
            type
          }
          framedDispatchDate: metafield(
            namespace: "my_fields"
            key: "framed_dispatch_date"
          ) {
            value
            type
          }
          collectionSet: metafield(namespace: "custom", key: "set_collection") {
            value
            type
          }
          maximumSalesCapacity: metafield(
            namespace: "custom"
            key: "maximum_sales_capacity"
          ) {
            value
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          tags
          onlineStoreUrl
          featuredImage {
            altText
            url
          }
          variants(first: 10) {
            edges {
              node {
                title
                price {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_PRICE_AND_TAGS_BY_ID_QUERY = gql`
  query ProductPriceTags($id: ID!, $country: CountryCode)
  @inContext(country: $country) {
    node(id: $id) {
      ... on Product {
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        tags
      }
    }
  }
`;

export const ALL_PRODUCTS_PRICE_QUERY = gql`
  query ProductsPrice(
    $country: CountryCode
    $filters: String!
    $limit: Int!
    $after: String
  ) @inContext(country: $country) {
    products(query: $filters, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($id: ID, $variantCount: Int) {
    product(id: $id) {
      availableForSale
      title
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      tags
      totalInventory
      description
      featuredImage {
        url
      }
      variants(first: $variantCount) {
        edges {
          node {
            id
            sku
            priceV2 {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const ALL_PRODUCTS_COUNT_QUERY = gql`
  query AllProductsCount(
    $country: CountryCode
    $filters: String!
    $limit: Int!
    $after: String
  ) @inContext(country: $country) {
    products(query: $filters, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          productType
          editionSize: metafield(namespace: "my_fields", key: "edition_size") {
            value
          }
        }
      }
    }
  }
`;

export const PRODUCT_BY_TAG_QUERY = gql`
  query ProductsByTag($country: CountryCode, $filters: String!, $limit: Int)
  @inContext(country: $country) {
    products(query: $filters, first: $limit) {
      edges {
        node {
          createdAt
          id
          title
          productType
          editionSize: metafield(namespace: "my_fields", key: "edition_size") {
            value
          }
          artistName: metafield(namespace: "my_fields", key: "artist_name") {
            value
          }
          launchDate: metafield(namespace: "my_fields", key: "launch_date") {
            value
          }
          manifoldListingId: metafield(
            namespace: "custom"
            key: "manifold_listing_id"
          ) {
            value
          }
          auctionFloorPrice: metafield(
            namespace: "custom"
            key: "auction_floor_price"
          ) {
            value
          }
          cryptoPrice: metafield(
            namespace: "my_fields"
            key: "crypto_display_price"
          ) {
            value
          }
          closingDate: metafield(
            namespace: "my_fields"
            key: "timed_release_closing_date"
          ) {
            value
          }
          releaseType: metafield(namespace: "my_fields", key: "release_type") {
            value
          }

          priceRange {
            maxVariantPrice {
              amount
            }
          }
          tags
          handle
          onlineStoreUrl
          featuredImage {
            altText
            url
          }
          variants(first: 10) {
            edges {
              node {
                title
                price {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_PURCHASE_DETAILS_QUERY = gql`
  query ProductPurchaseDetails($ids: [ID!]!, $country: CountryCode)
  @inContext(country: $country) {
    nodes(ids: $ids) {
      ... on Product {
        id
        title
        handle
        variants(first: 25) {
          edges {
            node {
              id
              sku
              title
              availableForSale
              requiresShipping
              price {
                amount
                currencyCode
              }
            }
          }
        }
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        tags
        manifoldListingId: metafield(
          namespace: "custom"
          key: "manifold_listing_id"
        ) {
          value
          type
        }
        auctionFloorPrice: metafield(
          namespace: "custom"
          key: "auction_floor_price"
        ) {
          value
          type
        }
        editionSize: metafield(namespace: "my_fields", key: "edition_size") {
          value
          type
        }
        artistName: metafield(namespace: "my_fields", key: "artist_name") {
          value
          type
        }
        launchDate: metafield(namespace: "my_fields", key: "launch_date") {
          value
          type
        }
        framingOffered: metafield(
          namespace: "my_fields"
          key: "framing_offered"
        ) {
          value
          type
        }
        unframedDispatchDate: metafield(
          namespace: "my_fields"
          key: "unframed_dispatch_date"
        ) {
          value
          type
        }
        framedDispatchDate: metafield(
          namespace: "my_fields"
          key: "framed_dispatch_date"
        ) {
          value
          type
        }
        closingDate: metafield(
          namespace: "my_fields"
          key: "timed_release_closing_date"
        ) {
          value
          type
        }
        collectionSet: metafield(namespace: "custom", key: "set_collection") {
          value
          type
        }
        maximumSalesCapacity: metafield(
          namespace: "custom"
          key: "maximum_sales_capacity"
        ) {
          value
        }
      }
    }
  }
`;

export const DEFAULT_GIFTCARDS_QUERY = gql`
  query GiftCards($filters: String!) {
    collection(handle: $filters) {
      products(first: 100) {
        edges {
          node {
            id
            title
            description
            tags
            variants(first: 5) {
              edges {
                node {
                  id
                  price {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const VARIANT_IDS_TO_SLUGS = gql`
  query VariantIDsToSlugs($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on ProductVariant {
        id
        product {
          handle
        }
      }
    }
  }
`;

export const VARIANT_AVAILABILITY = gql`
  query VariantAvailability($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on ProductVariant {
        id
        availableForSale
      }
    }
  }
`;

/**
 ****** @Deprecated ******
 ****** Please create new queries/mutations using .graphql files******
 */
